import React, { useEffect, useState } from 'react';
import { Box, Grid, Drawer, IconButton, Typography, CircularProgress, Stack, Divider } from '@mui/material';
import { ChildFriendlySharp, CloseOutlined, StarRounded } from '@mui/icons-material';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import AddonIconComponent from '../../components/AddonIconComponent';
import AddonIconComponent from './AddonIconComponent';
import Images from '../utils/Images';

const ProductDrawer = ({
  open,
  onClose,
  selectedProduct,
  handleAddToCart,
  quantity,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  selectedAddons,
  handleAddonSelect,
  totalPrice,
  totalMrpPrice,
  offerPercentage,
  addingToCart,
}) => {

  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [isMultiple, setIsMultiple] = useState(true);

  console.log('absdcabsdcfbasdcfadsfcajsdcfasdfhnkojandsf', outletData?._id);
  

  return (
    <Drawer open={open} onClose={onClose} anchor="bottom">
      <Box className="productDrawer">
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
        >
          <CloseOutlined sx={{ fontSize: '12px' }} />
        </IconButton>
        <Grid container>
          <Grid item xs={5} sx={{ justifyContent: 'center' }}>
            <Box className="imageBox">
              <img className="image" src={selectedProduct?.displayImageUrl || selectedProduct?.displayImage} alt="Product" />
            </Box>
          </Grid>
          <Grid item xs={7} sx={{ paddingLeft: '2%' }}>
            <Box className="productDetails">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography className="productName" sx={{ textAlign: 'left' }}>{selectedProduct?.name}</Typography>
              </Box>
              <Typography className="productInfo">{selectedProduct?.description}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <>
                    {selectedProduct?.foodType === 'VEG/VEGAN' ? (
                      <Stack direction={'row'}>
                        <img className='vegNonveg' src={Images.veg} alt='veg' />
                        <img style={{ height: '20px', marginRight: '4px' }} className='vegNonveg' src={Images.vegan} alt='vegan' />
                      </Stack>
                    ) : (
                      <>
                        {selectedProduct && (
                          <img style={{ height: '20px' }} className='vegNonveg' src={selectedProduct.foodType === 'VEG' ? Images.veg : (selectedProduct.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct.foodType === 'VEG' ? 'veg' : (selectedProduct.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                        )}
                      </>
                    )}
                  </>
                  <Typography sx={{ color: '#6B6C6E', paddingLeft: '7px' }}>{selectedProduct?.calories} kal</Typography>
                </Box>
                <Box sx={{ width: '1.5px', height: '20px', backgroundColor: '#6B6C6E', marginX: '8px' }} />
                <Typography sx={{ color: '#6B6C6E' }}>{selectedProduct?.weight} {selectedProduct?.weightUnit}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {selectedProduct && (
          <>
            {selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct.addons.length > 0 && (
              <Box className="addonsBox">
                {selectedProduct.addons
                .filter((addon) => !addon.excludedOutlets.includes(outletData?._id))
                .map((addon) => {
                  return (
                    <Grid container key={addon._id} sx={{ padding: '10px 10px 7px 10px', backgroundColor: '#fff', marginBottom: '10px', borderRadius: '18px' }}>
                      <Grid item xs={3} sx={{ display: 'flex', paddingTop: '10px' }}>
                        <Typography className="addonTitle">{addon.addonTitle}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {addon.addonValues
                          .slice()
                          .filter((addon) => addon?.status !== 'INACTIVE' && !addon.iconInfo)
                          .filter((addon) => !addon.excludedOutlets.includes(outletData?._id))
                          .map((addonValue) => {
                            const isSelected = addon?.isMultipleSelect ? Array.isArray(selectedAddons[addon?._id]) && selectedAddons[addon?._id].some((selected) => selected._id === addonValue._id) : selectedAddons[addon?._id]?._id === addonValue?._id;
                            const isDefault = addonValue?.isDefault;
                            return (
                              <Grid
                                container
                                key={addonValue._id}
                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
                              >
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                  <Box
                                    className="addonItem"
                                    onClick={() =>
                                      handleAddonSelect(addon._id, {
                                        ...addonValue,
                                        offerPrice: addonValue.offerPrice ?? 0,
                                        mrp: addonValue.mrp ?? 0,
                                      }, addon?.isMultipleSelect)
                                    }
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '3px 0 10px 0' }}
                                  >
                                    <Typography className="addonValue" sx={{ color: '#00A4E2' }}>
                                      {addonValue?.subLabel}
                                    </Typography>
                                    <Box className={`addonSelect ${isSelected ? 'selected' : 'unselected'}`}>
                                      {addonValue?.value ?? 0} <span style={{ paddingLeft: '7px' }}>+₹{addonValue?.offerPrice ?? 0}</span>
                                      {isSelected && !isDefault && (
                                        <CloseOutlined sx={{ margin: 0, padding: 0, fontSize: '15px', backgroundColor: '#fff', borderRadius: '50%', color: '#09A5F7', margin: '0 0px 0 7px' }} />
                                      )}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          })}
                        <Grid container flexDirection="row" justifyContent="flex-end" alignItems="baseline" sx={{ gap: '10px' }}>
                          {addon.addonValues
                            .slice()
                            .filter((addon) => addon?.status !== 'INACTIVE' && addon?.iconInfo)
                            .map((addonValue) => {
                              const isSelected = selectedAddons[addon?._id]?._id === addonValue?._id || (!selectedAddons[addon?._id] && addonValue?.isDefault);
                              const icon = addonValue.iconInfo ? (
                                <AddonIconComponent iconInfo={addonValue?.iconInfo} selected={isSelected} />
                              ) : null;
                              return (
                                <Grid item key={addonValue._id} xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                  <Box
                                    className="addonItem"
                                    onClick={() =>
                                      handleAddonSelect(addon._id, {
                                        ...addonValue,
                                        offerPrice: addonValue.offerPrice ?? 0,
                                        mrp: addonValue.mrp ?? 0,
                                      })
                                    }
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                  >
                                    <Box className="addonIcon">{icon}</Box>
                                    <Typography className="addonValue" sx={{ color: '#0E4961' }}>
                                      {addonValue.subLabel}
                                    </Typography>
                                    <Typography className="addonValue" sx={{ color: '#118E06', padding: '1px 0 7px 0' }}>
                                      +₹{addonValue.offerPrice ?? 0}
                                    </Typography>
                                  </Box>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            )}
          </>
        )}
        <Box className="priceAndButton">
          <Box className="addRemoveItem">
            <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
            <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
            <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
          </Box>
          <>
            {outletData?.isEventOutlet === selectedProduct?.isEventProduct ? (
              addingToCart ? (
                <Box className="d-button">
                  <CircularProgress size={18} sx={{ color: '#fff' }} /> Adding to cart
                </Box>
              ) : (
                <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "d-button"} onClick={!selectedProduct?.isExhausted ? handleAddToCart : null}>
                  {selectedProduct?.isExhausted ? (
                    "Out Of Stock"
                  ) : (
                    <>
                      <span>Add</span>
                      <span className="divider" />
                      <span>₹{(parseFloat(totalPrice) * parseInt(quantity)).toFixed(2)}</span>
                    </>
                  )}
                </Box>
              )
            ) : (
              <Box className="exhaustedButton" sx={{ flex: '0 0 auto', marginLeft: '20px' }}>
                <span style={{ fontSize: '16px' }}>Order this at our store</span>
              </Box>
            )}
          </>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ProductDrawer;